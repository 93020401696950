import { observable, action, makeObservable, runInAction, computed } from 'mobx';
import { WebStorageStateStore } from 'oidc-client-ts';

import { RootStore } from './Root.store';
import ApiWrapper from '../utilities/apiWrapper';
import { Startup } from '../models/Startup';
import { TeamsUserLocale } from '../models/TeamsUserLocale';
import { STORED_USER_LOCALE } from '../utilities/constants';
import { Consent } from 'src/models/Consent';
import ExceptionTypes from 'src/utilities/exceptionTypes';
import { AadUser } from 'src/models/AadUser';
import GraphApiWrapper from 'src/utilities/graphApiWrapper';
import { GraphClient } from 'src/auth/TeamsGraphAuth';

type StartupData = Startup | undefined;

export default class UserProfileStore {
  rootStore: RootStore;
  webStorageStateStore = new WebStorageStateStore({ store: window.localStorage });

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  @observable profile: any = null;
  @observable isDeviceConnected: boolean = false;
  @observable startupData: StartupData = undefined;
  @observable globalConsentContents: Consent[] = [];
  @observable targetedConsentContents: Consent[] = [];

  @computed
  get consentContents() {
    return [...this.globalConsentContents, ...this.targetedConsentContents];
  }

  @action
  setProfile = (value: any) => {
    this.profile = value;
  };

  @action
  setIsDeviceConnected = (value: boolean) => {
    this.isDeviceConnected = value;
  };

  @action
  updateProfile = async (): Promise<void> => {
    const apiWrapper = new ApiWrapper(this.rootStore.AppAuthStore.getAccessToken.bind(this.rootStore.AppAuthStore));
    const profile = await apiWrapper.getProfile();

    if (profile.Data) {
      this.setProfile(profile.Data.me);
    }
  };

  getStartupData = async (): Promise<StartupData> => {
    const shouldLoadStartupData = this.startupData === undefined || this.startupData === null;

    if (shouldLoadStartupData) {
      await this.loadStartupData();
    }

    return this.startupData;
  };

  @action
  loadStartupData = async (): Promise<void> => {
    const apiWrapper = new ApiWrapper(this.rootStore.AppAuthStore.getAccessToken.bind(this.rootStore.AppAuthStore));
    const startup = await apiWrapper.startup();

    if (startup) {
      this.startupData = startup;
    }
  };

  loadGlobalConsents = async (): Promise<Consent[]> => {
    const apiWrapper = new ApiWrapper(this.rootStore.AppAuthStore.getAccessToken.bind(this.rootStore.AppAuthStore));
    const globalConsentStatus = await apiWrapper.loadGlobalConsentStatus();
    let globalConsentContents: Consent[] = [];

    if (globalConsentStatus.NewConsent && globalConsentStatus.consentVersionIds?.length) {
      const loadConsentPromises = globalConsentStatus.consentVersionIds.map(async (consentId) => {
        const content = await apiWrapper.loadGlobalConsent(consentId);
        return { id: `${consentId}`, content };
      });
      globalConsentContents = await Promise.all(loadConsentPromises);
    }

    return globalConsentContents;
  };

  loadTargetedConsents = async (): Promise<Consent[]> => {
    const apiWrapper = new ApiWrapper(this.rootStore.AppAuthStore.getAccessToken.bind(this.rootStore.AppAuthStore));
    const targetedConsentStatus = await apiWrapper.loadTargetedConsents();

    if (!targetedConsentStatus.consents?.length) return [];

    const curentLang = this.rootStore.LocalizationStore.getCurrentLanguage;
    let filteredConsents = targetedConsentStatus.consents.filter((consent, _, arr) => {
      const consentLang = consent.language.toLowerCase();

      // Consent Id may be duplicated due to multiple languages
      const consentsWithSameId = arr.filter((item) => item.consent_id === consent.consent_id);
      const isDuplicated = consentsWithSameId.length > 1;
      let matchedLang = true;

      if (isDuplicated) {
        const found = consentsWithSameId.find((item) => item.language === curentLang);
        matchedLang = found ? consentLang === curentLang : consentLang === consentsWithSameId[0].language;
      }

      return !consent.global && matchedLang;
    });
    filteredConsents = filteredConsents.sort((a, b) => Number(a.consent_id) - Number(b.consent_id));

    const targetedConsentContents = filteredConsents.map((consent) => {
      return { id: `${consent.consent_id}`, content: consent.consent_html };
    });

    return targetedConsentContents;
  };

  @action
  loadConsents = async () => {
    const loadConsentsPromises = [this.loadGlobalConsents(), this.loadTargetedConsents()];
    const [globalConsentContents, targetedConsentContents] = await Promise.all(loadConsentsPromises);

    runInAction(() => {
      this.globalConsentContents = globalConsentContents;
      this.targetedConsentContents = targetedConsentContents;
    });
  };

  @action
  signConsents = async () => {
    const apiWrapper = new ApiWrapper(this.rootStore.AppAuthStore.getAccessToken.bind(this.rootStore.AppAuthStore));
    const globalConsentIds = this.globalConsentContents.map((consent) => consent.id);
    const targetedConsentIds = this.targetedConsentContents.map((consent) => consent.id);

    const signGlobalConsentPromises = globalConsentIds.map(async (id) => await apiWrapper.signGlobalConsent(id));
    const signTargetedConsentPromise = apiWrapper.signTargetedConsents(targetedConsentIds);

    await Promise.all([...signGlobalConsentPromises, signTargetedConsentPromise]);

    runInAction(() => {
      this.globalConsentContents = [];
      this.targetedConsentContents = [];
    });
  };

  storeUserLocale = async (teamsUserLocale: TeamsUserLocale): Promise<void> => {
    const key = `${STORED_USER_LOCALE}_${teamsUserLocale.oid}`;
    const { oid, locale } = teamsUserLocale;

    if (!oid || !locale) {
      throw new Error(ExceptionTypes.USER_LOCALE_DATA, {
        cause: new Error(`invalid data: ${JSON.stringify({ oid: String(oid), locale: String(locale) })}`),
      });
    }
    const apiWrapper = new ApiWrapper(this.rootStore.AppAuthStore.getAccessToken.bind(this.rootStore.AppAuthStore));
    const localStorageData: TeamsUserLocale = JSON.parse((await this.webStorageStateStore.get(key)) || '{}');
    if (localStorageData.locale !== teamsUserLocale.locale) {
      await apiWrapper.storeUserLocale(teamsUserLocale);
      this.webStorageStateStore.set(key, JSON.stringify(teamsUserLocale));
    }
  };

  getAadUsers = async (graphClient: GraphClient, aadUserIds: string[]): Promise<AadUser[]> => {
    const graphApiWrapper = new GraphApiWrapper(graphClient);
    return graphApiWrapper.getAadUsers(aadUserIds);
  };

  loadAllDeviceSyncInfo = async () => {
    const apiWrapper = new ApiWrapper(this.rootStore.AppAuthStore.getAccessToken.bind(this.rootStore.AppAuthStore));
    const result = await apiWrapper.getAllDeviceSyncInfo();
    const connectedDevices = result.filter((device) => device.connected);
    this.setIsDeviceConnected(!!connectedDevices.length);
  };

  getUserDisplayName = () => {
    return (
      this.rootStore.AppAuthStore.teamsUserState?.teamsUser?.displayName ||
      this.profile?.full_name ||
      this.profile?.user_name ||
      ''
    );
  };
}
