import { observer } from 'mobx-react-lite';
import { Flex, Image } from '@fluentui/react-northstar';

import './fading.css';
import splashSreenStyles from './SplashScreen.styles';
import { useStore } from 'src/stores/Root.store';

interface ISplashScreenProps {
  children?: React.ReactElement;
}

const SplashScreen: React.FC<ISplashScreenProps> = observer(({ children }) => {
  const { LocalizationStore } = useStore();
  const signinNote = LocalizationStore.getString('Fow_SignIn_Note');

  return (
    <Flex className={'fadeIn'} hAlign="center" vAlign="center" styles={splashSreenStyles.backgroundStyle} column>
      <Image id="limeadeLogoImage" src="./webmd-limeade-logo.png" styles={splashSreenStyles.logoStyle} />

      {children}

      <Flex hAlign="center" vAlign="center" gap="gap.smaller" styles={splashSreenStyles.noteStyle}>
        <p>{signinNote}</p>
      </Flex>
    </Flex>
  );
});

export default SplashScreen;
